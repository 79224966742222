import React from "react"
import { graphql } from "gatsby"

import Page from '../components/Page';
import Section from '../components/Section';
import Typography from "../components/Typography";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Page title={frontmatter.title}>
      <Section>
        <Typography type='heading' preset={1}>{frontmatter.title}</Typography>
        <Typography type='paragraph' preset={2}>{frontmatter.date}</Typography>
          <div className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
          />
      </Section>
    </Page>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "DD MMM YYYY")
        slug
        title
      }
    }
  }
`
